import { TWO_DECIMAL_PATTERN } from "./../utils/pattern";
import moment from "moment";
import {
  arrowDownKeyCode,
  arrowLeftKeyCode,
  arrowRightKeyCode,
  arrowUpKeyCode,
  backSpaceKeyCode,
  deleteKeyCode,
  enterKeyCode,
  tabSpacekeyCode,
} from "../utils/constants";
import {
  IsNullOrEmptyArray,
  IsNullOrUndefined,
  IsStringNullEmptyOrUndefined,
} from "../utils/null-check";
import * as XLSX_STYLE from "xlsx-js-style";
import {
  ActionTypeEntity,
  AssignedModuleData,
  ModuleEntity,
  UserInfoEntity,
  WaltzFeaturedCalculatedSolutionEntity,
} from "../interface";
import { SecondaryFactorsEnum, StorageKeyEnum } from "../utils/enum";
import {
  getLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
} from "./storage-service";
import { SolutionDetails } from "../interface/order";
import { StorageEditedPricingVersionEntity } from "../interface/pricing-version";
import { TreeStructureEnity } from "../interface/panel-only";

export const removeSpaceOnFirstCharacter = (event: any): void => {
  if (event.target.selectionStart === 0 && event.code === "Space") {
    event.preventDefault();
  }
};

export const checkValidInputOnKeyDown = (event: any, regExp: RegExp): void => {
  var key = event.which || event.keyCode;

  const keyChecks =
    key &&
    key !== tabSpacekeyCode &&
    key !== backSpaceKeyCode &&
    key !== deleteKeyCode &&
    key !== enterKeyCode &&
    key !== arrowLeftKeyCode &&
    key !== arrowUpKeyCode &&
    key !== arrowRightKeyCode &&
    key !== arrowDownKeyCode;

  if (!regExp.test(event.key) && keyChecks) {
    event.preventDefault();
  }
};

export const capitalizeLetter = (str: string) => {
  return str.toUpperCase();
};

export const capitalizeFirstLetter = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const validateFileExtension = (
  fileName: string,
  fileExtension: string[]
): boolean => {
  const extension: string | undefined = fileName.split(".").pop();
  if (extension) {
    return fileExtension.some((ext) => extension.includes(ext));
  }
  return false;
};

export const scrollTop = (): void => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const getBase64FromFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const IsFormValid = (obj: object): boolean => {
  let count = 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(obj).forEach(([key, value]) => {
    if (!IsStringNullEmptyOrUndefined(value)) {
      count += 1;
    }
  });

  return count === 0 ? true : false;
};

export const displayDateFormat = (dateStr: string): string => {
  return moment(dateStr).format("DD/MM/YYYY");
};

export const displayDateTimeFormat = (dateStr: string): string => {
  return moment(dateStr).format("DD/MM/YYYY HH:mm A");
};

export const checkActionAuthorization = (
  action: ActionTypeEntity,
  module: ModuleEntity
) => {
  let index: number = -1;
  const userStr: string | null = localStorage.getItem(
    StorageKeyEnum.JB_GLASS_USER_INFO
  );
  if (userStr && !IsStringNullEmptyOrUndefined(userStr)) {
    const userInfo: UserInfoEntity = JSON.parse(userStr);
    const modules: AssignedModuleData[] = userInfo.assignedModule || [];
    index = modules.findIndex(
      (e) => e.action === action && e.moduleName === module
    );
  }
  return index !== -1 ? true : false;
};

export const render30CharcterAfterDot = (rowData: string): string => {
  const name = rowData || "";

  const truncatedName = name.length > 30 ? name.substring(0, 30) + "..." : name;

  return truncatedName;
};

export const vehicleAutoSpaceValid = (str: string) => {
  const alphanumericIBAN = str.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
  const formattedIBAN = alphanumericIBAN.replace(
    /(\D{0,2})(\d{0,2})(\D{0,2})(\d{0,4})/,
    `$1${""} $2${""} $3${""} $4`
  );
  return formattedIBAN.trim();
};

export const reanderNumberOfCharcterAfterDot = (
  rowData: string,
  num: number
): string => {
  const name = rowData || "";

  const truncatedName =
    name.length > num ? name.substring(0, num) + "..." : name;

  return truncatedName;
};

function isValidDate(dateString: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) return false;

  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export const generateExcel = (
  wsData: (string | number | null)[][],
  columns: string[],
  fileName: string,
  style: boolean
) => {
  const ws = XLSX_STYLE.utils.aoa_to_sheet(wsData);
  const ref = ws["!ref"];

  if (style) {
    const firstRowStyle = { font: { sz: 14, bold: true } };

    if (ref) {
      const range = XLSX_STYLE.utils.decode_range(ref);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: 0 };
        const cellRef = XLSX_STYLE.utils.encode_cell(cellAddress);
        ws[cellRef].s = firstRowStyle;
      }
    }
  }

  const accountingStyleWithOutDecimalValue = {
    numFmt: "#,###,##0;-#,###,##0;",
  };
  const accountingStyleWithDecimalValue = {
    numFmt: "#,###,##0.###;-#,###,##0.###;",
  };

  for (const key in ws) {
    if (Object.prototype.hasOwnProperty.call(ws, key)) {
      let value = ws[key].v;
      if (isValidDate(value)) {
        ws[key].t = "d";
      }
    }
  }
  columns.forEach((columnName) => {
    const columnIndex = wsData[0].indexOf(columnName);
    if (columnIndex !== -1) {
      for (let rowIndex = 1; rowIndex < wsData.length; rowIndex++) {
        const cellRef = XLSX_STYLE.utils.encode_cell({
          r: rowIndex,
          c: columnIndex,
        });
        if (ws[cellRef]) {
          const cellValue = ws[cellRef].v;
          if (cellValue === 0) {
            ws[cellRef].s = accountingStyleWithOutDecimalValue;
          } else {
            const hasDecimal = Number.isInteger(cellValue);
            ws[cellRef].s = hasDecimal
              ? accountingStyleWithOutDecimalValue
              : accountingStyleWithDecimalValue;
          }
        }
      }
    }
  });

  const setColumnWidths = (
    ws: XLSX_STYLE.WorkSheet,
    wsData: (string | number | null)[][]
  ) => {
    const columnWidths: number[] = [];

    wsData.forEach((row) => {
      Object.keys(row).forEach((key: any, colIndex) => {
        const cellValue = row[key];
        if (cellValue !== undefined && cellValue !== null) {
          const cellWidth = cellValue.toString().length;
          columnWidths[colIndex] = Math.max(
            columnWidths[colIndex] || 0,
            cellWidth
          );
        }
      });
    });

    ws["!cols"] = columnWidths.map((width) => ({ wch: width + 5 }));
  };

  setColumnWidths(ws, wsData);

  const wb = XLSX_STYLE.utils.book_new();
  XLSX_STYLE.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX_STYLE.writeFile(wb, fileName);
};

export const storeWaltzSolutions = (
  orderId: string,
  solution: WaltzFeaturedCalculatedSolutionEntity[]
): void => {
  const str: string | null = getSessionStorageItem(
    `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`
  );

  if (str && !IsStringNullEmptyOrUndefined(str)) {
    let parseData: WaltzFeaturedCalculatedSolutionEntity[] | [] =
      JSON.parse(str);

    if (parseData && !IsNullOrEmptyArray(parseData)) {
      for (const sol of solution) {
        const index = parseData.findIndex((e) => e.uuid === sol.uuid);

        if (index === -1) {
          (parseData as WaltzFeaturedCalculatedSolutionEntity[]).push(sol);
        } else {
          parseData[index] = sol;
        }
      }
    } else {
      parseData = solution;
    }
    setSessionStorageItem(
      `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`,
      JSON.stringify(parseData)
    );
  } else {
    setSessionStorageItem(
      `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`,
      JSON.stringify(solution)
    );
  }
};

export const fetchStoredWaltzSolutions = (
  orderId: string
): null | SolutionDetails[] | [] => {
  let parseData: SolutionDetails[] | [] = [];

  const str: string | null = getSessionStorageItem(
    `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`
  );

  if (!str || IsNullOrUndefined(str)) {
    return null;
  }

  if (str && !IsStringNullEmptyOrUndefined(str)) {
    parseData = JSON.parse(str);
  }

  return parseData;
};

export const fetchStoredWaltzSingleSolution = (
  orderId: string,
  uuid: string
): null | SolutionDetails => {
  let solution: SolutionDetails | null = null;

  const str: string | null = getSessionStorageItem(
    `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`
  );

  if (!str || IsNullOrUndefined(str)) {
    return null;
  }

  if (str && !IsStringNullEmptyOrUndefined(str)) {
    const parseData: SolutionDetails[] | [] = JSON.parse(str);
    const index = parseData.findIndex((e) => e.uuid === uuid);

    if (index !== -1) {
      solution = parseData[index];
    }

    return solution;
  }

  return solution;
};

export const fetchLastSolutionNo = (orderId: string): number => {
  let solutionNo: number = 0;

  const str: string | null = getSessionStorageItem(
    `${StorageKeyEnum.WALTZ_ORDERS_SOLUTIONS}_${orderId}`
  );

  if (!str || IsNullOrUndefined(str)) {
    return 0;
  }

  if (str && !IsStringNullEmptyOrUndefined(str)) {
    const parseData: SolutionDetails[] | [] = JSON.parse(str);
    solutionNo = IsNullOrEmptyArray(parseData)
      ? 0
      : parseData[parseData.length - 1].solutionNo;
  }

  return solutionNo;
};

export const downloadCsvFile = async (excelUrl: string, fileName: string) => {
  const response = await fetch(excelUrl);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const fetchedEditedModulesData =
  (): null | StorageEditedPricingVersionEntity => {
    const str: string | null = getLocalStorageItem(
      StorageKeyEnum.EDITED_MODULES_FOR_PRICING_VERSION
    );

    let obj: null | StorageEditedPricingVersionEntity = null;

    if (str && !IsStringNullEmptyOrUndefined(str)) {
      obj = JSON.parse(str);
    }

    return obj;
  };

export const handleCancelClick = () => {
  const cancelButtons = document.querySelectorAll(".p-row-editor-cancel");
  cancelButtons.forEach((button) => {
    (button as HTMLElement).click();
  });
};

export const handleCheckNaN = (value: string) => {
  const isNaN =
    !TWO_DECIMAL_PATTERN.test(value) && value !== "-" && value !== "";
  return isNaN;
};

export const regionDisplayName = (secondaryFactor: string): string => {
  let displayName = "";

  if (secondaryFactor === SecondaryFactorsEnum.WITH_IN) {
    displayName = "Regional";
  }
  if (secondaryFactor === SecondaryFactorsEnum.CROSS) {
    displayName = "Cross";
  }
  if (secondaryFactor === SecondaryFactorsEnum.NA) {
    displayName = "Undefined";
  }
  if (secondaryFactor === SecondaryFactorsEnum.EXPORT) {
    displayName = "Export";
  }

  return displayName;
};

export const compareArray = (arr1: any, arr2: any): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    let obj1 = arr1[i];
    let obj2 = arr2[i];
    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
      return false;
    }
  }
  return true;
};

export const formatValue = (
  value: number,
  format: "CR" | "Lakh" | "K"
): any => {
  if (format === "CR") {
    return (value / 1e7).toFixed(2) + " CR";
  } else if (format === "K") {
    const intValue = Math.floor(value);
    return intValue >= 1000
      ? // `${Math.trunc(intValue / 1000)}k`
      `${(intValue / 1000).toFixed(2)}K`
      : `${(intValue / 1000).toFixed(2)}K`;
  } else {
    return value.toString();
  }
};

export const loggedInUserInfo = (): UserInfoEntity | null => {
  const str: string | null = getLocalStorageItem(
    StorageKeyEnum.JB_GLASS_USER_INFO
  );
  if (!str) {
    return null;
  }

  if (str && !IsStringNullEmptyOrUndefined(str)) {
    const userInfo: UserInfoEntity = JSON.parse(str);
    return userInfo;
  }
  return null;
};

export const checkActiveOrInActiveStructure = (id: string, treeStructure: TreeStructureEnity[], oldModels: string[] | [] = []): boolean => {
  for (const element of treeStructure) {
    if (element.id === id && !element.isActive && !oldModels.some((item) => item === element.id)) {
      return false;
    }
    if (element.children) {
      for (const subCategory of element.children) {
        if (subCategory.id === id && !subCategory.isActive && !oldModels.some((item) => item === subCategory.id)) {
          return false;
        }
        if (subCategory.children) {
          for (const finish of subCategory.children) {
            if (finish.id === id && !finish.isActive && !oldModels.some((item) => item === finish.id)) {
              return false;
            }
            if (finish.children) {
              for (const varinat of finish.children) {
                if (varinat.id === id && !varinat.isActive && !oldModels.some((item) => item === varinat.id)) {
                  return false;
                }
              }
            }
          }
        }
      }
    }
  }
  return true;
};

export const getStructureIds = (data: TreeStructureEnity[]): string[] => {
  let ids: string[] = [];

  for (const element of data) {
    ids.push(element.id)
    if (element.children) {
      for (const subCategory of element.children) {
        ids.push(subCategory.id)
        if (subCategory.children) {
          for (const finish of subCategory.children) {
            ids.push(finish.id)
            if (finish.children) {
              for (const variant of finish.children) {
                ids.push(variant.id);
              }
            }
          }
        }
      }
    }
  }

  return ids;
};

export const numberWithCommas = (x: string): string => {
  return x.toString().split(".")[0].length > 3
    ? x
      .toString()
      .substring(0, x.toString().split(".")[0].length - 3)
      .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    "," +
    x.toString().substring(x.toString().split(".")[0].length - 3)
    : x.toString();
}

export const NothingArchitect = {
  id: 0,
  architect: "Nothing from suggestion",
  name: "Nothing from suggestion",
  city: ""
};
