import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StorageKeyEnum } from '../utils/enum';
import { IsNullOrUndefined, IsStringNullEmptyOrUndefined } from '../utils/null-check';
import { UserInfoEntity } from '../interface';
import { render30CharcterAfterDot } from '../service/shared.service';
import { removeLocalStorageItem } from '../service/storage-service';

function Header() {

    const navigate = useNavigate();

    const [userData, setUserData] = useState<UserInfoEntity | null>(null);

    useEffect(() => {
        const userStr: string | null = localStorage.getItem(StorageKeyEnum.JB_GLASS_USER_INFO);

        if (userStr && !IsStringNullEmptyOrUndefined(userStr)) {
            const userInfo: UserInfoEntity = JSON.parse(userStr);
            setUserData(userInfo);
        }
    }, []);

    const fetchUserNameLetter = (): string => {
        let letters = '';
        if (userData && !IsNullOrUndefined(userData)) {
            const userName: string = userData.userFullName;
            const userNameArr: string[] = userName.split(' ');
            const firstWord: string = userNameArr[0];
            const lastWord: string = userNameArr[userNameArr.length - 1];
            letters = firstWord.charAt(0).toUpperCase() + lastWord.charAt(0).toUpperCase();
        }

        return letters;
    };

    return (
        <>
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                    <div className="me-3">
                        <button className="sidebar-favicon-logo" type="button">
                            <img src='assets/images/favicon.png' alt="jb-glass" />
                        </button>
                    </div>
                    <div>
                        <a className="navbar-brand brand-logo" href="index.html">
                            <img src="assets/images/logo.png" alt="logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" href="index.html">
                            <img src="assets/images/logo.png" alt="logo" />
                        </a>
                    </div>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-top">
                    <ul className="navbar-nav">
                        <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                            <h1 className="welcome-text">Hello,&nbsp;
                                <span className="text-black fw-bold">
                                    {userData && render30CharcterAfterDot(userData.userFullName)}
                                </span>

                                <small>&nbsp;( {userData && userData.roleName} ) </small>
                            </h1>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        {/* <li className="nav-item">
                            <form className="search-form" action="#">
                                <i className="icon-search"></i>
                                <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
                            </form>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link count-indicator cursor-pointer" id="notificationDropdown" data-bs-toggle="dropdown">
                                <i className="icon-message icon-lg"></i>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
                                <span className="cursor-pointer dropdown-item py-3 border-bottom">
                                    <p className="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                                    <span className="badge badge-pill badge-primary float-right">View all</span>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-alert m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                                        <p className="fw-light small-text mb-0"> Just now </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-settings m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">Settings</h6>
                                        <p className="fw-light small-text mb-0"> Private message </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-airballoon m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                                        <p className="fw-light small-text mb-0"> 2 days ago </p>
                                    </div>
                                </span>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link count-indicator" id="countDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="icon-notification"></i>
                                <span className="count"></span>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="countDropdown">
                                <span className="dropdown-item py-3">
                                    <p className="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                                    <span className="badge badge-pill badge-primary float-right">View all</span>
                                </span>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face10.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face12.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face1.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                            </div>
                        </li> */}
                        <li className="nav-item dropdown d-none d-lg-block user-dropdown">
                            <span className="nav-link" id="UserDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="name-circle"> {fetchUserNameLetter()} </span>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                                <div className="dropdown-Header text-center p-2">
                                    <span className="name-circle" style={{ margin: '0.5rem auto 0 auto' }}> {fetchUserNameLetter()} </span>

                                    <p className="mb-1 mt-3 font-weight-semibold">{userData && render30CharcterAfterDot(userData.userFullName)}</p>
                                    <p className="fw-light text-muted mb-0">{userData && render30CharcterAfterDot(userData.emailAddress)}</p>
                                </div>
                                <span className="dropdown-item">
                                    <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>
                                    My Profile
                                    {/* <span className="badge badge-pill badge-danger">1</span> */}
                                </span>
                                <span className="dropdown-item" onClick={() => navigate('/user/change-password')}>
                                    <img src="assets/images/reset-password.svg" alt="Reset Password" className='me-2' />
                                    Change Password
                                </span>
                                <span className="dropdown-item" onClick={() => {
                                    removeLocalStorageItem(StorageKeyEnum.JB_GLASS_AUTH_TOKEN);
                                    removeLocalStorageItem(StorageKeyEnum.JB_GLASS_USER_INFO);
                                    navigate('/');
                                }}>
                                    <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                                    Sign Out
                                </span>
                            </div>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="offcanvas">
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            </nav >
        </>
    )
}

export default Header