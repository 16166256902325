/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import { APIResponseEntity } from '../../interface/api-responce';
import { ViewLedgerDetailsRespEntity } from '../../interface/ledger-form';
import { APIGetLedgerDetailsByOrderId } from '../../service/api-service';
import { toastOptObj } from '../../utils/constants';
import { DelivaryAddressStatusEnum, OrderStatusEnumEntity } from '../../utils/enum';
import { IsNullOrUndefined, IsStringNullEmptyOrUndefined } from '../../utils/null-check';

export default function ViewLedgerDetails({ modelOrderId }: { modelOrderId: string }) {

    const [ledgerDetails, setLedgerDetails] = useState<ViewLedgerDetailsRespEntity | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [orderId, setOrderId] = useState<string>('');

    const param = useParams();

    useEffect(() => {
        const fetchDefaultData = async (): Promise<void> => {
            if (param && param.id && !IsStringNullEmptyOrUndefined(param.id)) {
                setOrderId(param.id);
            } else {
                if (modelOrderId && !IsStringNullEmptyOrUndefined(modelOrderId)) {
                    setOrderId(modelOrderId);
                }
            }
        }
        fetchDefaultData();
    }, [param, modelOrderId]);

    useEffect(() => {
        const fetchDefaultData = async (): Promise<void> => {
            setLedgerDetails(null);
            if (orderId && !IsStringNullEmptyOrUndefined(orderId)) {
                await fetchLedgerDetailsByOrderId();
            }
        }
        fetchDefaultData();
    }, [orderId]);

    const fetchLedgerDetailsByOrderId = async () => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetLedgerDetailsByOrderId(orderId);
        setIsLoading(false);

        if (resp) {
            if (resp.data && resp.status === 200) {
                const axiosResp = resp.data;
                if (axiosResp && axiosResp.data && axiosResp.status &&
                    axiosResp.statusCode === 200) {
                    setLedgerDetails(axiosResp.data);
                } else {
                    toast.error(axiosResp.message, { ...toastOptObj });
                }
            }
            else {
                toast.error(resp.data.message, { ...toastOptObj });
            }
        }
    };

    const statusBodyTemplate = (orderStatus: string): string => {
        const status =
            orderStatus === OrderStatusEnumEntity.DRAFT
                ? "Draft"
                : orderStatus === OrderStatusEnumEntity.PIPELINE
                    ? "Pipeline"
                    : orderStatus === OrderStatusEnumEntity.COMMERCIALHOLD
                        ? "Commercial Hold"
                        : orderStatus === OrderStatusEnumEntity.COMMERCIALWIN
                            ? "Win"
                            : orderStatus === OrderStatusEnumEntity.OLDWIN
                                ? "Old Win"
                                : orderStatus === OrderStatusEnumEntity.HANDOVER
                                    ? "Handover"
                                    : orderStatus === OrderStatusEnumEntity.EDITED
                                        ? "Edited"
                                        : orderStatus === OrderStatusEnumEntity.EXPIRED
                                            ? "Expired"
                                            : orderStatus === OrderStatusEnumEntity.LOST
                                                ? "Lost"
                                                : orderStatus === OrderStatusEnumEntity.PRECLOSURE
                                                    ? "Preclosure"
                                                    : "";

        return status;
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            {
                ledgerDetails && !IsNullOrUndefined(ledgerDetails) &&
                <div className="content-wrapper">
                    <div className="col-lg-12 mx-auto d-flex text-center">
                        <div className="auth-form-light text-left p-5 w-100">
                            <div className="brand-logo">
                                <img src="assets/images/logo.png" alt="logo" />
                            </div>
                            <h4 className='mt-3'>
                                {`Ledger Details of ${ledgerDetails.orderData.projectName}`}
                            </h4>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                {
                                                    ledgerDetails.accountDetails.orderStatus &&
                                                    !IsStringNullEmptyOrUndefined(ledgerDetails.accountDetails.orderStatus) &&
                                                    <h6 className="mt-2 fw-bold">
                                                        {statusBodyTemplate(ledgerDetails.accountDetails.orderStatus)}
                                                    </h6>
                                                }
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.accountDetails.orderNumber}
                                                </h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.accountDetails.orderStatusDate}
                                                </h6>
                                            </div>

                                            <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.ledgerDetails.salesPerson}
                                                </h6>
                                            </div>

                                            <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">DUE</h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.accountDetails.due}
                                                </h6>
                                            </div>

                                            <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">OVER DUE</h6>
                                                {
                                                    ledgerDetails.accountDetails.Overdue &&
                                                        !IsStringNullEmptyOrUndefined(ledgerDetails.accountDetails.Overdue)
                                                        ?
                                                        <>
                                                            {
                                                                ledgerDetails.accountDetails.Overdue !== "-"
                                                                    ?
                                                                    <h6 className="mt-2 fw-bold text-danger">
                                                                        {ledgerDetails.accountDetails.Overdue}
                                                                    </h6>
                                                                    :
                                                                    <h6 className="mt-2 fw-bold">
                                                                        -
                                                                    </h6>
                                                            }
                                                        </>
                                                        :
                                                        <h6 className="mt-2 fw-bold">
                                                            &nbsp;
                                                        </h6>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12 col-md-12 mb-3'>
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row consign-block">
                                            {
                                                ledgerDetails && ledgerDetails.ledgerDetails && ledgerDetails.ledgerDetails.consigner &&
                                                <div className="col-md-6 col-12">
                                                    <h4 className="card-title">Consigner </h4>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consigner && ledgerDetails.ledgerDetails.consigner.officeName} </p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4">
                                                            <span className='text-muted'> GST </span>
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consigner && ledgerDetails.ledgerDetails.consigner.GSTNO} </p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-8">
                                                            <span className='text-muted'> ADDRESS </span>
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consigner && ledgerDetails.ledgerDetails.consigner.address} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                ledgerDetails && ledgerDetails.ledgerDetails && ledgerDetails.ledgerDetails.consignee &&
                                                <div className="col-md-6 col-12">
                                                    <h4 className="card-title">Consignee </h4>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consignee && ledgerDetails.ledgerDetails.consignee.officeName} </p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4">
                                                            <span className='text-muted'> GST </span>
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consignee && ledgerDetails.ledgerDetails.consignee.GSTNO} </p>
                                                        </div>
                                                        <div className="col-sm-12 col-md-8">
                                                            <span className='text-muted'> ADDRESS </span>
                                                            <p className='fw-bold'> {ledgerDetails && ledgerDetails.ledgerDetails.consignee && ledgerDetails.ledgerDetails.consignee.address} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">GRAND TOTAL</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.grandTotal}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">DEALER DISCOUNT</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.dealerDiscount}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">SALE</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.sale}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">DISCOUNT</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.discount}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">DEALER PRICE</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.dealerAmount}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">COLLECTION</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.collection}
                                                        </h6>
                                                    </div>


                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">FINAL AMOUNT</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.finalAmount}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        {
                                                            ledgerDetails.accountDetails.PDC && ledgerDetails.accountDetails.PDC !== 0
                                                                ?
                                                                <>
                                                                    <h6 className="m-sm-0 text-gray">PDC COLLECTION</h6>
                                                                    <h6 className="mt-2 fw-bold">
                                                                        {ledgerDetails.accountDetails.PDC}
                                                                    </h6>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>

                                                    <div className="col-6 col-md-4 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">WEBAPP COLLECTION</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.accountDetails.webappCollection}
                                                        </h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">ID REFERENCE</h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.ledgerDetails.idReference} :- {ledgerDetails.ledgerDetails.idReferenceNumber}
                                                </h6>
                                            </div>

                                            <div className="col-md-4 col-12 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">BILLING NAME </h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.ledgerDetails.billingName}
                                                </h6>
                                            </div>
                                            <div className="col-md-5 col-12 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">BILLING ADDRESS</h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.ledgerDetails.billingAddress}
                                                </h6>
                                            </div>
                                            <div className="col-md-7 col-12 mb-3 mb-md-2">
                                                <h6 className="m-sm-0 text-gray">CUSTOMER PURCHASE ORDER NO</h6>
                                                <h6 className="mt-2 fw-bold">
                                                    {ledgerDetails.ledgerDetails.customerPurchaseOrderNo}
                                                </h6>
                                            </div>
                                            {
                                                ledgerDetails.ledgerDetails.purchaseOrderPdfUrl &&
                                                <div className="col-md-5 col-12 mb-3 mb-md-2">
                                                    <h6 className="m-sm-0 text-gray">CUSTOMER PURCHASE ORDER PDF</h6>
                                                    <h6 className="mt-2 fw-bold">
                                                        <a href={ledgerDetails.ledgerDetails.purchaseOrderPdfUrl}>Click here for view pdf</a>
                                                    </h6>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5 className='fw-bold mb-3'> Delivery Address </h5>
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 1 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.addressLine1! : ""}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 2 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.addressLine2! : ""}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 3 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.addressLine3! : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">CITY </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.city! : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">STATE </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.state! : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">PIN CODE </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.pinCode! : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">LANDMARK </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.delivaryAddress ? ledgerDetails.ledgerDetails.delivaryAddress.landmark! : ""}
                                                        </h6>
                                                    </div>

                                                    {
                                                        ledgerDetails.ledgerDetails.delivaryAddress &&
                                                        !IsNullOrUndefined(ledgerDetails.ledgerDetails.delivaryAddress) &&
                                                        ledgerDetails.ledgerDetails.delivaryAddress.googlePinLink &&
                                                        !IsStringNullEmptyOrUndefined(ledgerDetails.ledgerDetails.delivaryAddress.googlePinLink) &&
                                                        <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                            <h6 className="m-sm-0 text-gray">GOOGLE PIN LINK </h6>
                                                            <h6 className="mt-2 fw-bold">
                                                                <a href={ledgerDetails.ledgerDetails.delivaryAddress.googlePinLink}>
                                                                    {ledgerDetails.ledgerDetails.delivaryAddress.googlePinLink}
                                                                </a>
                                                            </h6>
                                                        </div>
                                                    }

                                                    {
                                                        ledgerDetails.ledgerDetails.delivaryAddress &&
                                                        !IsNullOrUndefined(ledgerDetails.ledgerDetails.delivaryAddress) &&
                                                        <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                            <h6 className="m-sm-0 text-gray">DELIVERY ADDRESS</h6>
                                                            <h6 className="mt-2 fw-bold">
                                                                {ledgerDetails.ledgerDetails.delivaryAddressStatus &&
                                                                    (ledgerDetails.ledgerDetails.delivaryAddressStatus === DelivaryAddressStatusEnum.DEALEROFFICE ?
                                                                        ledgerDetails.ledgerDetails.consignee ?
                                                                            ledgerDetails.ledgerDetails.consignee.officeName : ledgerDetails.ledgerDetails.consigner?.officeName : `${ledgerDetails.ledgerDetails.delivaryAddressStatus.toLocaleUpperCase()} ADDRESS`)}
                                                            </h6>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className='fw-bold mb-3'> Contact Details </h4>
                                                <div className="row">
                                                    <h6 className='mb-2 fw-bold'>Direct Client</h6>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">NAME</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {
                                                                ledgerDetails.ledgerDetails.contactDetails &&
                                                                    ledgerDetails.ledgerDetails.contactDetails.directClient &&
                                                                    ledgerDetails.ledgerDetails.contactDetails.directClient.salutation
                                                                    ? ledgerDetails.ledgerDetails.contactDetails.directClient.salutation : ''
                                                            }
                                                            {
                                                                ledgerDetails.ledgerDetails.contactDetails &&
                                                                    ledgerDetails.ledgerDetails.contactDetails.directClient &&
                                                                    ledgerDetails.ledgerDetails.contactDetails.directClient.name
                                                                    ? ledgerDetails.ledgerDetails.contactDetails.directClient.name : ''
                                                            }
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">MOBILE NUMBER</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.directClient &&
                                                                ledgerDetails.ledgerDetails.contactDetails.directClient.mobile
                                                                ? ledgerDetails.ledgerDetails.contactDetails.directClient.mobile : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">EMAIL ADDRESS</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.directClient &&
                                                                ledgerDetails.ledgerDetails.contactDetails.directClient.emailAddress
                                                                ? ledgerDetails.ledgerDetails.contactDetails.directClient.emailAddress : ''}
                                                        </h6>
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    <h6 className='mb-2 fw-bold'>Site Person</h6>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">NAME</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson.salutation
                                                                ? ledgerDetails.ledgerDetails.contactDetails.sitePerson.salutation : ''}
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson.name
                                                                ? ledgerDetails.ledgerDetails.contactDetails.sitePerson.name : ''}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">MOBILE NUMBER</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson.mobile
                                                                ? ledgerDetails.ledgerDetails.contactDetails.sitePerson.mobile : ''}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">EMAIL ADDRESS</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.contactDetails &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson &&
                                                                ledgerDetails.ledgerDetails.contactDetails.sitePerson.emailAddress
                                                                ? ledgerDetails.ledgerDetails.contactDetails.sitePerson.emailAddress : ''}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className='fw-bold mb-3'>Architect Firm Details [{ledgerDetails.ledgerDetails.isAchitectFrimVerify ? "Verified" : "Non Verified"}] </h4>
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">COMPANY NAME</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.companyName}
                                                        </h6>
                                                    </div>
                                                    {/* <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">WEBSITE</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                        {ledgerDetails.ledgerDetails.consultantDetails.}
                                                        </h6>
                                                    </div> */}

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 1 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.addressLine1}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 2 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.addressLine2}
                                                        </h6>
                                                    </div>

                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">ADDRESS LINE 3 </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.addressLine3}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">CITY </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.city}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">STATE </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.state}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">PIN CODE </h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.consultantDetails.pinCode}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4 className='fw-bold mb-3'>Architect details [{ledgerDetails.ledgerDetails.isArchitectDetailVerify ? "Verified" : "Non Verified"}] </h4>
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">NAME</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.principleArchitect.salutation} {ledgerDetails.ledgerDetails.principleArchitect.firstName} {ledgerDetails.ledgerDetails.principleArchitect.lastName}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">MOBILE NUMBER</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.principleArchitect.mobile}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">EMAIL ADDRESS</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.principleArchitect.emailAddress}
                                                        </h6>
                                                    </div>
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">USER TYPE</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.principleArchitect.userType}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-md-12 mb-3">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                        <h6 className="m-sm-0 text-gray">PRO LEVEL STATUS</h6>
                                                        <h6 className="mt-2 fw-bold">
                                                            {ledgerDetails.ledgerDetails.proLevel}
                                                        </h6>
                                                    </div>
                                                    {
                                                        ledgerDetails.ledgerDetails.proLevel === 'PROPLUS' &&
                                                        <div className="col-6 col-md-3 mb-3 mb-md-2">
                                                            <h6 className="m-sm-0 text-gray">PRO PLUS PERCENTAGE</h6>
                                                            <h6 className="mt-2 fw-bold">
                                                                {ledgerDetails.ledgerDetails.proPlusPercent}
                                                            </h6>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
